/* .location-header{
    font-size: 3rem;
    width: 100%;
    font-weight: 800;
    text-align: center;
}
.location{
    padding: 3rem;
    font-size: 2rem;
}

.location .options{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
}

.location .options button{
    background: var(--orange);
    padding: 1.5rem;
    color: #eee;
    cursor: pointer;
    text-transform: capitalize;
}

#text-places:focus {
    box-shadow:  0 8px 8px -4px lightblue;
}

.pac-container {
    z-index: 1055 !important;
}

.else{
    border: 1px solid #000; 
    border-radius: 50%;
    line-height: 0.1em;
    margin: 10px 0 20px;
    padding: 6px;
    text-align: center;
}

.btn-confirm-location{
    background: var(--secondary-color);
    color: var(--primary-color);
    padding: 8px;
}


@media(max-width:600px) {
    .header-1 .location-modal .box-modal{
        width: 100vw;
    }
} */



.location h5 {
    font-size: 2.2rem;
    font-weight: bolder;
    text-transform: capitalize;
}

.location .header button {
    background: none;
    font-size: 3rem;
}

.location .body img {
    width: 100px;
    height: 100px;
    margin: 30px;
}

.location .body span {
    font-size: 1.5rem;
    color: var(--sub-text-color);
    text-align: center;

}

.location .body button {
    margin-top: 30px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
    background-color: #fff;
    width: 100%;
    color: #000;
    font-size: 1.845rem;
}

.location .body button:hover {
    background: var(--secondary-color);
    color: #fff;
}


.location .body .oval-continer {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.location .body .oval-continer .oval {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
}

.location .body .oval-continer .oval .separator-text {
    position: relative;
    display: inline-block;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 50%;
}

.location .body .oval-continer .oval .separator-text::before {
    right: 100%;
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 1px solid #ccc;
    width: 7pc;
    margin: 0 7px;

}

.location .body .oval-continer .oval .separator-text .or {
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bbb;
    text-align: center;
}


.location .body .oval-continer .oval .separator-text::after {
    left: 100%;
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 1px solid #ccc;
    width: 7pc;
    margin: 0 7px;
}

.location .body .input-container {
    width: 100%;
}

.location .body input[type=text] {
    width: 100%;
    background: rgba(155, 151, 151, 0.205);
    border: 1px solid rgba(155, 151, 151, 0.205);
    border-radius: 5px;
    padding: 6px;
    font-size: 1.845rem;
}

.location .map-content {
    display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.location .map-content p,.location .map-content button {
    font-size: 2rem;
}


/* z-index problem of munualy text select location */
.pac-container {
    z-index: 1055 !important;
}