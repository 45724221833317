/* progressbar */
/*  */
.order-list .order-list-table {
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 8px;

}

.order-list .order-list-table thead {
    background-color: var(--secondary-color);
}

.order-list .order-list-table thead tr th:first-child,
.order-list .order-list-table tbody tr th:first-child,
.order-list .order-list-table thead tr th:last-child,
.order-list .order-list-table tbody tr th:last-child {
    padding: 5px 15px;
}

.order-list .order-list-table thead tr th {
    color: #fff;
    padding: 5px;
}

.order-list .order-list-table thead tr th:first-child {
    border-top-left-radius: 8px;
}

.order-list .order-list-table thead tr th:last-child {
    border-top-right-radius: 8px;
}

.order-list .order-list-table tbody tr.last-column th:first-child {
    border-bottom-left-radius: 8px;
}


.order-list .order-list-table tbody tr.last-column th:last-child {
    border-bottom-right-radius: 8px;
}

.order-list .order-list-table tbody tr {
    border-bottom: 1px solid #ddd;
}

.order-list .order-list-table tbody tr:last-child {
    border: none;
}

.order-list .order-list-table tbody tr th {
    padding: 5px;
    font-weight: 500;
}

.order-list .order-list-table tbody tr th.button-container {
    display: flex;
    gap: 8px;
}
@media (max-width: 467px) {
    .order-list .order-list-table tbody tr th.button-container {
        flex-direction: column;
    }   
}
.order-list .order-list-table tbody tr th.button-container button {
    padding: 4px 10px;
    color: #fff;
    border-radius: 5px;
    text-transform: capitalize;
    font-size: 1.455rem;
}

.order-list .order-list-table tbody tr th.button-container button.track {
    background: var(--secondary-color);
}

.order-list .order-list-table tbody tr th.button-container button.Invoice {
    background: rgb(57, 151, 176);
}

.no-orders {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.no-orders img {
    width: 30vw;
}

.no-orders p {
    font-size: 2.455rem;
    font-weight: bolder;
    margin-top: 20px
}