#footer {
  padding-top: 40px;
  font-size: 1.7rem;
  background: var(--primary-color);
  position: relative;
  bottom: 0;
}

@media (max-width:479px) {
  #footer{
    bottom: 17px;
  }
}

#footer h5 {
  border-bottom: 1px solid #777575;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 2.3rem;
  font-weight: 600;
  text-transform: uppercase;
}

#footer a,
#footer button.link {
  color: #ffffff;
  text-decoration: none !important;
  text-transform: capitalize;
  background-color: transparent;
  text-align: start;
  -webkit-text-decoration-skip: objects;
  font-weight: 600;
  display: flex;
}

#footer a:hover,
#footer button.link:hover {
  color: var(--secondary-color);
}

#footer ul {
  list-style-type: disc;
}

#footer ul li {
  margin-bottom: 10px;
}

#footer ul li::marker {
  color: var(--secondary-color);
}

#footer .download-button {
  height: 40px;
  background: inherit;
}

#footer .download-button img {
  width: 100%;
  height: 100%;
}

#footer .copyright {
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

#footer .copyright p {
  margin: auto;
  color: #fff;
  text-transform: none;
}

@media (max-width: 48rem) {
  #footer {
    margin-bottom: 35px;
  }
}

.copyright .company_name {
  color: var(--secondary-color);
  font-weight: 800;
}
