.faq {
    padding-bottom: 30px;
    min-height: 75rem;
}

.faq-container {
    /* min-height: 100vw; */

    padding: 30px;
    /* margin: 30px 0;
    background: #fff;
    border-radius: 8px; */
    font-size: 2.435rem;
}

.faq-container .no-faq,
.faq-container .faq-card {
    background: #fff;
    border-radius: 8px;
}

.faq-container .no-faq{
    padding: 30px;
}

.faq-container .faq-card button{
    padding: 10px;
    background: none;
    width: 100%;
    font-weight: bolder;
    text-transform: uppercase;
    text-align: start;
    display: flex;
    justify-content: space-between;
}

.faq-container .faq-card button span.icon{
    padding: 3px;
    border-radius: 5px;
    background: var(--secondary-color);
    display: none;
}

.faq-container .faq-card button span.icon.active{
    display: block;
}

.faq-container .faq-card .answer{
    padding: 10px;
    width: 100%;
    font-size: 2.055rem;
    color: var(--sub-text-color);
}

.no-faq{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.no-faq img{
    width: 30vw;
}

.no-faq p{
    font-size: 2.455rem;
    font-weight: bolder;
    margin-top: 20px
}
