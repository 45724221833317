.home-element {
    padding: 15px 0;
}

.home-section {
    position: relative;
}

.section {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self);
}

.home-section .home-container {
    /* max-width: 1200px;
    min-height: 516px; */
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    height: 100%;
    margin-bottom: 50px;
}

.category_section_header p {
    font-size: 18px;
    line-height: 32px;
    text-transform: uppercase;
    color: var(--primary-color);
    letter-spacing: 2px;
    font-weight: 700 !important;
    margin: 0;
}

@media (max-width: 479px) {
    .category_section_header p{
        font-size: 14px;
    }
}

.category_section_header a {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400 !important;
    letter-spacing: 1px;
    color: var(--secondary-color);
    text-decoration: none;
    margin-left: 10px;

}

.prev-arrow-category {
    position: relative;
    display: inline-block;
    text-align: center;
    color: #888 !important;
    top: 0;
    right: 5%;
    padding: 8px;
    background: #fff;
    box-shadow: 0 0 1px 1px rgb(20 23 28 / 10%), 0 3px 1px 0 rgb(20 23 28 / 10%);
    font-size: 15px;
    z-index: 10;
    opacity: 0.7 !important;
    visibility: visible;
    border-radius: 100%;
    transition: all 300ms linear;
}

.next-arrow-category {
    position: relative;
    display: inline-block;
    text-align: center;
    color: #888 !important;
    top: 0;
    right: 0;
    padding: 8px;
    background: #fff;
    box-shadow: 0 0 1px 1px rgb(20 23 28 / 10%), 0 3px 1px 0 rgb(20 23 28 / 10%);
    font-size: 15px;
    z-index: 10;
    opacity: 0.7 !important;
    visibility: visible;
    border-radius: 100%;
    transition: all 300ms linear;
}

@media(max-width:75rem) {}

@media(max-width:48rem) {
}

@media(max-width:30rem) {
    .home-element {
        margin: 0px;
        /* height: 320px; */

    }
}

@media(max-width:48rem) {
    .home-page {
        height: auto;
        margin: 0;
        width: 100%;
    }
}