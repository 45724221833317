.slick-slider {
  flex: 0 0 auto;
  width: 100% !important;
  padding: 0 !important;
  padding-left: -2px;
}

.css-b62m3t-container {
  width: 100%;
}

.slick-prev {
  left: -36px !important;
  z-index: 10 !important;
}

.slick-prev::before {
  content: "" !important;
}

.prev-arrow {
  position: absolute;
  background: #fff;
  border-radius: 8px 0px 0px 8px;
  width: 40px;
  height: 80px;
  right: -20px;
  top: -250%;
  padding: 8px;
  box-shadow: 4px 0px 100px 5px rgba(0, 0, 0, 0.2);
  border: 5px solid #f7f7f7;
}

.prev-arrow:hover {
  background-color: #51bd88;
}

.next-arrow {
  position: absolute;
  background: #fff;
  border-radius: 0pc 8px 8px 0px;
  width: 40px;
  height: 80px;
  right: -20px;
  top: -250%;
  padding: 8px;
  box-shadow: 4px 0px 100px 5px rgba(0, 0, 0, 0.05);
  border: 5px solid #f7f7f7;
}
@media (max-width: 467px) {
  .next-arrow{
    right: -10;
  }
  .prev-arrow{
    right: -25px;
  }
}
.next-arrow:hover {
  background-color: #51bd88;
}

.slick-next {
  margin-right: 12px !important;
}

.slick-next::before {
  content: "" !important;
}

.m-50 {
  margin: 50px 0px;
}

.product-heading-container {
  background: var(--second-cards-color);
  border-radius: 8px;
  padding: 9px;
  margin-bottom: 20px;
}

.product_section {
  background: #ffffff;
  padding: 15px !important;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.product_title_content {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  position: relative;
}

.product_title_content::before {
  content: "";
  bottom: -3px;
  left: 0;
  position: absolute;
  background: #51bd88;
  width: 100px;
  height: 5px;
  border-radius: 10%;
}

.product_title_content span {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400 !important;
  letter-spacing: 1px;
  color: var(--secondary-color);
}

.variant_value {
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-top: 10px !important;
  margin-bottom: 0 !important;
  color: #adadad !important;
}

.variant_value_a {
  color: #adadad !important;
}

.product_title_content p {
  font-size: 18px;
  line-height: 32px;
  text-transform: uppercase;
  color: var(--primary-color);
  letter-spacing: 2px;
  font-weight: 700 !important;
  margin: 0;
}

.product_title_content a {
  background: rgba(81, 189, 136, 0.2);
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 16px;
  padding: 8px 16px;
  text-decoration: none;
  color: var(--secondary-color);
  text-align: center;
}

.product_title_content a:hover {
  background: var(--secondary-color);
  color: #fff;
}

.product_section_content {
  margin-top: 10px !important;
}

.product-card {
  color: var(--primary-color);
  background: var(--second-cards-color);
  border-radius: 8px;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  overflow: hidden;
}

.product-card:hover {
  box-shadow: 0 0 15px rgb(0 0 0 / 5%);
  transform: scale(1.003);
  border: 1px solid #51bd88 !important;

  cursor: pointer;
}

.product-card .image-container {
  position: relative;
}

.product-card .image-container span {
  font-size: 22px;
  background: #ffffff;
  line-height: 34px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 0px !important;
  border-radius: 100% !important;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 8%);
}

.product-card .image-container span:hover {
  background: var(--secondary-color);
}

.product-card .image-container a:hover svg {
  filter: invert(1);
}

.product-card .image-container img {
  height: auto;
  width: 100%;
  padding: 15px;
}

.product-card .product-card-body {
  height: 20%;
}

.product-card .product-card-body h3 {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
  color: #141a1f;
  width: 100%;
  float: left;
  letter-spacing: 0.2px;
  display: block;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-card .product-card-body h3:hover {
  color: #51bd88;
}

.dropDownLi {
  border-bottom: 1px solid #888;
}

.dropdown-menu {
  padding: 0 !important;
}

.dropdown-menu li:hover {
  background: var(--secondary-color) !important;
}

.dropdown-menu li:hover .react-share__ShareButton span {
  color: #fff !important;
}

.dropdown-menu li button {
  padding: 10px !important;
  padding: 10px;
}

.react-share__ShareButton {
  background-color: transparent;
  border: none;
  padding: 0px;
  font: inherit;
  display: flex;
  color: inherit;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.react-share__ShareButton span {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  margin-left: 5px;
}

.product-card .product-card-body select,
option {
  background-color: #f2f3f9;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 4px 10px;
  opacity: 0.7;
}



.variant_selection option {
  padding: 8px 16px;
  line-height: 9px;
  color: #333;
  font-size: 16px;
  font-weight: 400;
}

.product-card .product-card-body .price span {
  color: var(--secondary-color) !important;
  width: 100%;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 18px;
  color: #141a1f;
  width: 100%;
  float: left;
  letter-spacing: 0.2px;
}

.product-card .product-card-body .product_varients_drop {
  width: 100%;
  display: flex;
}

.product-card .product-card-footer button {
  background: none;
  font-size: 1.6rem;
  padding: 8px;
}

.product-card .product-card-footer .add-to-cart,
.product-card .product-card-footer .input-to-cart {
  display: none;
}

.product-card .product-card-footer .add-to-cart.active,
.product-card .product-card-footer .input-to-cart.active {
  display: block;
  text-transform: uppercase;
}

.product-card .product-card-footer .input-to-cart.active {
  background: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-card .product-card-footer .input-to-cart.active * {
  color: #fff;
}

.product-card .product-card-footer button:hover {
  background: var(--secondary-color);
  color: #fff;
}

#productlist {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 2rem;
}

#products {
  flex-wrap: nowrap !important;
}

#productlist .row .filter-container {
  display: inline-flex;
}

#productlist .row .filter-row {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
}

#productlist .row .filter-row > div {
  padding: 5px;
}

#productlist .row .filter-row h5 {
  font-size: 2.5rem;
  padding: 5px;
  border-bottom: 3px solid rgba(128, 128, 128, 0.253);
  border-image: linear-gradient(
      to right,
      var(--secondary-color) 10%,
      rgba(128, 128, 128, 0.253) 0%,
      rgba(128, 128, 128, 0.253) 75%
    )
    2;
}

#productlist .row .filter-row .image-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

#productlist .row .filter-row img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

#productlist .row .filter-row .brand-count {
  background: #51bd8980;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid var(--secondary-color);
  font-size: 1.5rem;
}

#productlist .row .filter-row .filter-bar:hover {
  background: rgba(0, 0, 0, 0.225);
}

#productlist .row .filter-row .filter-bar.active {
  border-right: 3px solid var(--secondary-color);
  background: #51bd8942;
}

#productlist .row .filter-row .slider {
  margin: 30px 0px;
  border-radius: 8px;
  position: relative !important;
}

#productlist .row .filter-row .slider .track {
  height: 5px;
  top: 0;
  border-radius: 8px;
}

#productlist .row .filter-row .slider .track.track-1 {
  background: var(--secondary-color);
}

#productlist .row .filter-row .slider .thumb {
  background: var(--secondary-color);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  width: 25px;
  top: -10px;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 2px 1px #51bd8980;
  position: absolute !important;
}

#productlist .row .filter-view {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
}

#productlist .row .filter-view .icon {
  padding: 0 5px;
}

#productlist .row .filter-view .icon.active {
  background: var(--secondary-color);
}

#productlist .row .filter-view .icon:hover {
  background: var(--secondary-color);
}

#productlist .row .filter-view .select {
  position: relative;
  display: flex;
  width: 8em;
  overflow: hidden;
}

.form-select {
  width: 100% !important;
}

#productlist .row .filter-view .select select,
option {
  appearance: none;
  outline: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  color: #000;
  background-color: #eee;
  background-image: none;
  cursor: pointer;
  font-size: 14px;
}

#productlist .row .filter-view .select select::-ms-expand {
  color: #000;
}

#productlist .row .filter-view .select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 5px;
  transition: 0.25s all ease;
  pointer-events: none;
  font-size: 1.3rem;
}

#productlist .row .product-card {
  display: flex;
}

#productlist .row .product-card.list-view {
  flex-direction: row;
  justify-content: space-around;
  max-height: 200px;
  max-width: 340px;
  min-width: 340px;
}

#productlist .row .product-card.list-view .image-container {
  width: fit-content;
  padding: 10px 0;
}

#productlist .row .product-card.list-view .image-container img {
  height: 95px;
  width: 95px;
}

#productlist .row .product-card.list-view .image-container a {
  float: left;
}

.productList_container {
  padding-left: 30px !important;
}
@media (max-width:467px) {
  .productList_container{
    padding-left: 0 !important;
  }
}
.productList_content {
  margin: 20px 0 !important;
  padding: 0 !important;
}

.product-details-view .top-wrapper {
  margin-top: 30px;
  background-color: #fff;
  border-radius: 10px;
}

.product-details-view .body-wrapper {
  background-color: #fff;
  font-size: 14px;
  display: flex;
  border-radius: 5px;
}

.product-details-view .body-row {
  display: flex;
  justify-content: center;
}

.product-details-view .image-wrapper {
  padding: 15px;
}

.product-details-view .image-wrapper .main-image {
  width: 100%;
  text-align: center;
  border-radius: 5px;
}

.product-details-view .image-wrapper .main-image img {
  width: 480px;
}

.product-details-view .image-wrapper .sub-images-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
  position: relative;
}

.product-details-view
  .sub-images-container
  .slick-arrow.slick-next
  .next-arrow {
  position: absolute;
  background: #fff;
  border-radius: 0pc 8px 8px 0px;
  width: 40px;
  height: 40px;
  right: 0;
  left: auto;
  top: -14px;
  padding: 8px;
  box-shadow: 4px 0px 100px 5px rgb(0 0 0 / 5%);
  border: 5px solid #f7f7f7;
}

.related-product-container {
  position: relative;
}

.related-product-container .slick-arrow.slick-next .next-arrow,
.related-product-container .slick-arrow.slick-prev .prev-arrow {
  width: 40px;
  height: 80px;
  top: -30px;
  right: -10px;
}

.product-details-view .slick-arrow.slick-next {
  right: 0 !important;
  margin-right: 0 !important;
}

.product-details-view .slick-arrow.slick-prev {
  left: auto !important;
}

.product-details-view
  .sub-images-container
  .slick-arrow.slick-prev
  .prev-arrow {
  position: absolute;
  background: #fff;
  border-radius: 0pc 8px 8px 0px;
  width: 40px;
  height: 40px;
  right: auto;
  left: 0;
  top: -14px;
  padding: 8px;
  box-shadow: 4px 0px 100px 5px rgb(0 0 0 / 5%);
  border: 5px solid #f7f7f7;
}

.description-wrapper {
  margin: 30px 0 0 0;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
}

.description-wrapper img {
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

.description-wrapper .title {
  box-sizing: border-box;
  border: 0px;
  font-size: 20px;
  margin: 0px 0px 10px;
  outline: 0px;
  padding: 0px;
  vertical-align: baseline;
  overflow-wrap: break-word;
  line-height: 50px;
  font-weight: 800;
  color: #232323;
  border-bottom: 1px solid #8080802b;
  text-transform: uppercase;
}

.related-product-wrapper {
  margin: 20px auto;
}
.related-product-wrapper h5 {
  box-sizing: border-box;
  border: 0px;
  font-size: 20px;
  margin: 0px 0px 10px;
  outline: 0px;
  padding: 0px;
  vertical-align: baseline;
  overflow-wrap: break-word;
  line-height: 50px;
  font-weight: 800;
  color: #232323;
  padding: 15px;
  background-color: #fff;
  text-transform: uppercase;
  border-radius: 10px;
}

.product-details-view .image-wrapper .sub-images-container .sub-image {
  width: 90px;
  height: 90px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: auto;
  cursor: pointer;
}

.related-product-container .slick-prev {
  left: 5px !important;
  z-index: 10 !important;
}

@media (max-width: 480px) {
  .related-product-container .slick-dots {
    bottom: -17px;
  }

  .related-product-container .slick-dots li button:before {
    font-family: "slick";
    font-size: 8px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    color: var(--secondary-color);
  }
}

.related-product-container .slick-next {
  right: 8px;
}

@media (max-width: 350px) {
  .product-details-view .image-wrapper .sub-images-container .sub-image {
    width: auto;
  }
}

.product-details-view .image-wrapper .sub-images-container .sub-image.active {
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
}

.product-details-view .image-wrapper .sub-images-container .sub-image img {
  width: 100%;
  height: 100%;
}

.product-details-view .detail-wrapper {
  padding: 15px;
  width: 100%;
}

.product-details-view .detail-wrapper .top-section {
  display: flex;
  flex-direction: column;
}

.product-details-view .detail-wrapper .top-section p {
  color: #141a1f;
  text-align: left;
  font: normal normal bold 36px/8px;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #141a1f;
  opacity: 1;
  margin-bottom: 0;
}

.product-details-view .detail-wrapper .top-section .product-brand {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  font-size: 16px;
}

.product-details-view .detail-wrapper .top-section .product-brand .brand-name {
  color: #51bd88;
  margin-left: 5px;
}

.product-details-view .detail-wrapper .top-section .price {
  font-size: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #51bd88;
  text-align: left;
  font: normal normal bold 32px/10px;
  letter-spacing: 0px;
}

.product-details-view .detail-row .top-section .price::before,
.product-details-view .detail-row .top-section .not-price::before {
  content: "\20B9";
}

.product-details-view .detail-row .top-section .not-price {
  text-decoration: line-through;
}

.product-details-view .detail-wrapper .bottom-section .product-overview {
  margin-top: 50px;
  width: 100%;
  background-color: #f7f7f7;
  padding: 15px;
}

.product-details-view
  .detail-wrapper
  .bottom-section
  .product-overview
  .product-seller
  .seller-title,
.product-details-view
  .detail-wrapper
  .bottom-section
  .product-overview
  .product-tags
  .tag-title {
  padding: 10px 5px;
  font-size: 16px;
  font-weight: 600;
}
.product-details-view
  .detail-wrapper
  .bottom-section
  .product-overview
  .product-seller
  .seller-name,
.product-details-view
  .detail-wrapper
  .bottom-section
  .product-overview
  .product-tags
  .tag-name {
  color: var(--secondary-color);
  font-size: 16px;
}
.product-details-view .detail-wrapper .bottom-section .share-product-container {
  margin-top: 36px;
}
.product-details-view
  .detail-wrapper
  .bottom-section
  .share-product-container
  span {
  font-size: 14px;
  font-weight: 700;
}
.product-details-view .detail-wrapper .bottom-section .share-product-container,
.share-product {
  display: flex;
  list-style: none;
  align-items: center;
}

.product-details-view .detail-wrapper .bottom-section .share-product {
  margin: 0 !important;
}

.product-details-view .detail-wrapper .key-feature p,
.product-details-view .detail-wrapper .bottom-section p {
  font-weight: bold;
}
.product-details-view .body-wrapper .detail-wrapper .bottom-section {
  margin-top: 50px;
}
.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content {
  margin-top: 10px;
}

@media (max-width: 424px) {
  .product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content {
    display: block !important;
    gap: 3px;
  }
  .product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content #Add-to-cart-quickview {
    margin: 0 !important;
    padding: 8px;
  }
  .product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  .bottom-section-content #select-product-variant-quickview  {
    width: 100%;
  }
  
}
.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  #select-product-variant-productdetail {
  padding: 12px 18px;
  border-radius: 8px;
  font-size: 18px;
  color: #8b8b8b;
  font-weight: 600;
  background-color: #f7f7f7;
  cursor: pointer;
}
.product-details-view
  .body-wrapper
  .detail-wrapper
  .bottom-section
  #select-product-variant-quickview {
  padding: 12px 18px;
  border-radius: 8px;
  font-size: 18px;
  color: #8b8b8b;
  font-weight: 600;
  background-color: #f7f7f7;
  cursor: pointer;
}

.product-details-view .price.green-text svg {
  font-size: 24px;
}

.product-details-view .detail-wrapper .bottom-section button {
  margin: 0 10px;
  background: none;
  border: 1px solid #80808080;
  border-radius: 5px;
}

.product-details-view .detail-row .bottom-section button:hover {
  background: var(--secondary-color);
  color: #fff;
  font-size: 14px;
}

.product-details-view
  .detail-wrapper
  .bottom-section
  #Add-to-cart-productdetail {
  width: 35%;
}
@media (max-width: 424px) {
  .product-details-view .body-wrapper .detail-wrapper .bottom-section #select-product-variant-productdetail{
    width: 100%;
  }
  .product-details-view
  .detail-wrapper
  .bottom-section
  #Add-to-cart-productdetail {
  width: 35%;
  margin: 0;
  padding: 8px;
} 
}
.product-details-view
  .detail-wrapper
  .bottom-section
  #Add-to-cart-quickview {
  width: 35%;
}

.product-details-view .product-seller {
  margin-bottom: 10px;
}

.product-details-view .detail-wrapper .bottom-section .input-to-cart {
  background: #f7f7f7;
  display: inline-flex;
  padding: 5px 0;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  width: 35%;
}

.product-details-view .detail-wrapper .bottom-section .input-to-cart button {
  background: #8b8b8b;
  border-radius: 5px;
}
.product-details-view .detail-wrapper .bottom-section .wishlist-product {
  padding: 8px 12px;
  margin: 0 !important;
}
@media (max-width:434px) {
  .product-details-view .detail-wrapper .bottom-section .wishlist-product{
    margin: 15px 0 0 10px !important;
  }
}
.product-details-view .detail-wrapper .bottom-section button:hover,
.wishlist-product:hover svg path {
  background-color: #51bd88;
  color: #fff;
}
.product-details-view
  .detail-wrapper
  .bottom-section
  .input-to-cart.active
  span {
  background: #a7eeccc9;
  color: var(--secondary-color);
  padding: 8px 15px;
}

.wishlist-button {
  padding: 5px;
}

.product-details-view .top-row {
  background: #fff;
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
}

.product-details-view .description-row {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.product-details-view .description-row .title {
  font-size: 2.5rem;
  font-weight: bolder;
  text-transform: uppercase;
  border-bottom: 3px solid rgba(128, 128, 128, 0.253);
  border-image: linear-gradient(
      to right,
      var(--secondary-color) 10%,
      rgba(128, 128, 128, 0.253) 0%,
      rgba(128, 128, 128, 0.253) 75%
    )
    2;
}

.product-details-view .description-row .description {
  overflow-x: auto;
  scroll-behavior: smooth;
  font-size: 1.5rem;
}

.product-details-view .related-product-row {
  margin-bottom: 20px;
}

.product-details-view .related-product-row h5 {
  padding: 20px;
  font-size: 2.5rem;
  font-weight: bolder;
  text-transform: uppercase;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.related-product-container .product-card {
  margin: 6px;
}

.pagination,
.pagination li a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.pagination li {
  background-color: inherit;
}

.pagination a {
  font-weight: 500;
  padding-top: 1px;
  text-decoration: none;
  min-width: 44px;
  min-height: 44px;
  color: #000;
  box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.35);
}

.pagination li.active {
  background: var(--secondary-color);
  color: #fff;
  border-radius: 50%;
}

@media (max-width: 48rem) {
  .product-details-view .body-row {
    flex-direction: column;
    align-items: center;
  }

  .product-details-view .image-row {
    width: 250px;
  }

  .product-details-view .image-row .main-image {
    height: 240px;
  }

  .product-details-view .detail-row {
    border-left: none;
    border-top: 2px solid #eee;
    margin-left: 0px;
    margin-top: 20px;
    padding: 20px 5px;
  }

  .product-details-view .image-row .sub-images-container .sub-image {
    width: 50px;
    height: 50px;
  }

  .product-details-view .detail-row .top-section .product-overview {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-details-view
    .detail-row
    .top-section
    .product-overview
    .product-seller,
  .product-details-view
    .detail-row
    .top-section
    .product-overview
    .product-tags,
  .product-details-view
    .detail-row
    .top-section
    .product-overview
    .product-category {
    border-right: none;
    border-bottom: 1px solid #eee;
    width: 100%;
  }

  .product-details-view .description-row .description img {
    width: 90vw;
  }
}

/* Products Page */

.product_list_container {
  margin-top: 10px;
}

.product_list_container .product-card {
  margin: 0 10px;
}

@media (max-width: 764px) {
  .pagination a {
    min-width: 30px;
    min-height: 30px;
  }

  .product-details-view .image-wrapper {
    border-right: none;
  }
}

#eye:hover {
  background-color: rgba(56, 206, 116, 1) !important;
}

#eye {
  border-radius: 50%;
  margin: -5px;
}
