.cart-sidebar-container {
    padding: 20px;
}

.cart-sidebar-container .empty-cart {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cart-sidebar-container .empty-cart img {
    width: 100%;
}

.cart-sidebar-container .empty-cart p {
    font-size: 2.555rem;
    font-weight: bolder;
    color: #000;
    text-align: center;
}

.cart-sidebar-container .empty-cart span {
    color: var(--sub-text-color);
    font-size: 1.555rem;
    text-align: center;
    font-weight: 600;
}

.cart-sidebar-container .empty-cart button {
    margin-top: 20px;
    background: var(--secondary-color);
    padding: 8px 15px;
    color: #fff;
    text-transform: capitalize;
    font-size: 1.555rem;
    border-radius: 5px;
}

.cart-sidebar-container .cart-sidebar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 8px 16px;
    /* position: fixed;
    top: 0; */
}

.cart-sidebar-container .cart-sidebar-header .close-canvas {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 2.8125rem;
    width: 3.375rem;
    height: 3.375rem;
    color: #fff;
    background: none;
    border-radius: 50%;
}

.cart-sidebar-container .cart-sidebar-header h5 {
    font-size: 2.435rem;
    text-transform: capitalize;
    font-weight: 900;

}

.cart-sidebar-container .cart-sidebar-product {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.cart-sidebar-container .cart-sidebar-product .products-header {
    background: var(--secondary-color);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 15px;
    margin-top: 20px;
}

.cart-sidebar-container .cart-sidebar-product .products-header span {
    color: #fff;
    font-size: 1.855rem;
}


.cart-sidebar-container .cart-sidebar-product .products-container {
    display: block;
    margin-top: 20px;
    overflow-y: auto;
    scrollbar-width: 0;
    flex-grow: 1;
}

.cart-sidebar-container .cart-sidebar-product .products-container::-webkit-scrollbar {
    width: 0;
}

.cart-sidebar-container .cart-sidebar-product .products-container .cart-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    width: 100%;
    margin: 10px 0;
}

.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .left-wrapper {
    display: flex;
    width: 100%;
    gap: 8px;
}

.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .left-wrapper .image-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 3px;
    height: fit-content;
}


.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .left-wrapper .image-container img {
    height: 100px;

}

.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .left-wrapper .product-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}

.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .left-wrapper .product-details span {
    font-size: 1.855rem;
    font-weight: 800;
}

.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .left-wrapper .product-details .selected-variant-cart {
    font-size: 14px;
    font-weight: 400;
}

.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .left-wrapper .product-details .counter {
    height: 20px;
    display: flex;
    width: fit-content;
    align-items: center;
    border-radius: 3px;
    background: #a7eeccc9;
}


.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .left-wrapper .product-details .counter button {
    height: 100%;
    width: 20px;
    font-size: 1.755rem;
    background: var(--secondary-color);
    border-radius: 3px;

}

.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .left-wrapper .product-details .counter span {
    font-size: 1.555rem;
    font-weight: 500;
    padding: 0 12px;
    color: var(--secondary-color);
}

.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .cart-card-end {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .cart-card-end span {
    color: var(--secondary-color);
    font-size: 1.855rem;
    display: flex;
    align-items: center;
}

.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .cart-card-end .remove-product {
    color: #fff;
    font-size: 1.3rem;
    background: rgb(212 9 9);
    padding: 2px 5px;
    border-radius: 5px;
    text-transform: capitalize;
}


.cart-sidebar-container .cart-sidebar-footer {

    font-size: 1.855rem;
    font-weight: bolder;

}

.cart-sidebar-container .cart-sidebar-footer .heading {
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
}

.cart-sidebar-container .cart-sidebar-footer .total-amount span {
    color: var(--secondary-color);
}

.cart-sidebar-container .cart-sidebar-footer .summary {
    padding: 5px 0;
    font-size: 1.555rem;
    font-weight: 900;
    border-bottom: 1px solid #ddd;
}

.cart-sidebar-container .cart-sidebar-footer .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin-top: 30px;
}

.cart-sidebar-container .cart-sidebar-footer .button-container button {
    padding: 5px 15px;
    text-decoration: none;
    text-transform: capitalize;
    border-radius: 8px;
    font-weight: bolder;
}

.cart-sidebar-container .cart-sidebar-footer .button-container .view-cart {
    background: none;
    border: 1px solid #ddd;
    color: #000;
}

.cart-sidebar-container .cart-sidebar-footer .button-container .checkout {
    background: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: #fff;
}


.viewcart{
    min-height: 75vh;
}

.view-cart-container {
    display: flex;
    gap: 20px;
    padding: 30px 0;
    justify-content: center;
}

#viewcart .cover {
    height: 200px;
}

#viewcart .cover .title{
    position: relative;
    bottom: 50%;
    text-align: center;
}

#viewcart .cover .title h3{
    font-size: 2.385rem;
    font-weight: 800;
    color: #fff;
}
#viewcart .cover .title span{
    font-size: 1.985rem;
    font-weight: 600;
    color: #fff;
}

#viewcart .cover .title span.active{
    color: var(--secondary-color);
}


#viewcart .cover img {
    height: 100%;
    width: 100%;
    filter: brightness(50%);
    z-index: -1;
    background-size: cover;
    background-position: center center;
}

.view-cart-container .viewcart-product-wrapper {
    flex-grow: 1;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
}

.view-cart-container .viewcart-product-wrapper .product-heading h3 {
    font-size: 3.755rem;
    font-weight: bolder;
    text-transform: capitalize;
}

.view-cart-container .viewcart-product-wrapper .product-heading span {
    font-size: 1.755rem;
    font-weight: 600;
    text-transform: none;
}

.view-cart-container .viewcart-product-wrapper .product-heading .title {
    color: var(--secondary-color);
}

.view-cart-container .viewcart-product-wrapper .products-table thead {
    background: var(--secondary-color);
}


.view-cart-container .viewcart-product-wrapper .products-table thead tr th {
    color: #fff;
    font-size: 1.755rem;
    text-transform: capitalize;
    padding: 8px 15px;
}

.view-cart-container .viewcart-product-wrapper .products-table thead tr th.first-column,
.view-cart-container .viewcart-product-wrapper tbody tr th.first-column {
    padding: 8px 25px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.view-cart-container .viewcart-product-wrapper .products-table thead tr th.last-column,
.view-cart-container .viewcart-product-wrapper tbody tr th.last-column {
    padding: 8px 25px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}



.view-cart-container .viewcart-product-wrapper tbody tr th {
    font-size: 1.755rem;
    text-transform: capitalize;
    padding: 10px 15px;
}

.view-cart-container .viewcart-product-wrapper .products-table .products-image-container {
    display: flex;
    gap: 30px;
}

.view-cart-container .viewcart-product-wrapper .products-table .products-image-container .image-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 3px;
    height: 100px;
    width: 100px;
}

.view-cart-container .viewcart-product-wrapper .products-table .products-image-container .image-container img {
    height: 100%;
    width: 100%;
}

.view-cart-container .viewcart-product-wrapper .products-table .products-image-container select {
    margin-top: 20px;
    font-size: 1.455rem;
    background: rgba(128, 128, 128, 0.253);
    border-radius: 5px;
}

.view-cart-container .viewcart-product-wrapper tbody .price {
    color: var(--secondary-color);
}

.view-cart-container .viewcart-product-wrapper tbody .quantity>div {
    border-radius: 6px;
    border: 1px solid var(--secondary-color);
    display: flex;
    width: fit-content;
    background: #a7eeccc9;

}

.view-cart-container .viewcart-product-wrapper tbody .quantity button {
    background: var(--secondary-color);
    border-radius: 5px;
    padding: 4px 6px;
}

.view-cart-container .viewcart-product-wrapper tbody .quantity span {
    padding: 5px 15px;
    color: var(--secondary-color);
}

.view-cart-container .viewcart-product-wrapper tbody .remove button {
    background: none;
}


.view-cart-container .cart-summary-wrapper {
    background: #fff;
    border-radius: 8px;
    height: fit-content;
    flex-grow: 0.3;
}

.view-cart-container .cart-summary-wrapper .heading {
    font-size: 1.935rem;
    font-weight: bolder;
    text-align: center;
    background: var(--secondary-color);
    padding: 5px;
    border-radius: 8px;
}

.view-cart-container .cart-summary-wrapper .heading span {
    color: #fff;
}

.view-cart-container .cart-summary-wrapper .summary {
    padding: 20px;
    font-size: 1.555rem;
    font-weight: 700;
}

.view-cart-container .cart-summary-wrapper .summary>div {
    padding: 5px;
}

.view-cart-container .cart-summary-wrapper .summary .total {
    margin-top: 5px;
    border-top: 1px solid #eee;
}

.view-cart-container .cart-summary-wrapper .summary .total .total-amount span {
    color: var(--secondary-color);
}


.view-cart-container .cart-summary-wrapper .summary .button-container {
    background: var(--secondary-color);
    border-radius: 8px;
    margin-top: 30px;
}

.view-cart-container .cart-summary-wrapper .summary .button-container a {
    padding: 5px 15px;
    text-decoration: none;
    text-transform: capitalize;
    border-radius: 8px;
    font-weight: bolder;
    color: #fff;
}


.view-cart-container .empty-cart {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.view-cart-container .empty-cart img {
    width: 100%;
}

.view-cart-container .empty-cart p {
    font-size: 2.555rem;
    font-weight: bolder;
    color: #000;
    text-align: center;
}

.view-cart-container .empty-cart span {
    color: var(--sub-text-color);
    font-size: 1.555rem;
    text-align: center;
    font-weight: 600;
}

.view-cart-container .empty-cart button {
    margin-top: 20px;
    background: var(--secondary-color);
    padding: 8px 15px;
    color: #fff;
    text-transform: capitalize;
    font-size: 1.555rem;
    border-radius: 5px;
}

.view-cart-container .cart-summary-wrapper {
    background: #fff;
    border-radius: 8px;
}





@media(max-width:48rem) {

    .view-cart-container {
        flex-direction: column;
    }

    .view-cart-container .viewcart-product-wrapper .products-table .products-image-container {
        gap: 10px;
    }

    .view-cart-container .viewcart-product-wrapper .products-table .products-image-container .image-container {
        height: 40px;
        width: 40px;
    }

    .view-cart-container .viewcart-product-wrapper .products-table .products-image-container select{
        margin-top: 5px;
    }
    .view-cart-container .viewcart-product-wrapper .products-table thead tr th.first-column,
    .view-cart-container .viewcart-product-wrapper tbody tr th.first-column {
        padding: 8px 5px;
        
    }

    .view-cart-container .viewcart-product-wrapper .products-table thead tr th.last-column,
    .view-cart-container .viewcart-product-wrapper tbody tr th.last-column {
        padding: 8px 5px;
        
    }

    .view-cart-container .viewcart-product-wrapper tbody .quantity button{
        padding: 4px;
    }
    .view-cart-container .viewcart-product-wrapper tbody .quantity span{
        padding: 4px;
    }

    .view-cart-container .viewcart-product-wrapper .products-table thead tr th{
        padding: 8px 6px;

    }

    .view-cart-container .viewcart-product-wrapper tbody tr th{
        padding: 10px 6px;
    }
}