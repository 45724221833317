.offers{
    padding-top: 30px !important;
    margin-bottom: 30px !important;
    margin: 0;
    padding: 0;
}

.offer-container{
    background: var(--second-cards-color);
    gap: 30px;

}

.offer-container .offer-container-heading{
    padding-top: 9px;
}

.offer-container .offer-container-heading span{
    font-size: 2rem;
    text-transform: capitalize;
    color: var(--secondary-color);
}
.offer-container .offer-container-heading p{
    font-size: 3rem;
    text-transform: uppercase;
    color: var(--primary-color);
    font-weight: bolder;
    margin: 0;
}
@media (max-width: 479px) {
    .offer-container .offer-container-heading p{
        font-size: 14px;
    }
}
/* .offer-container .offer-container-body{
} */

.offer-container .offer-container-body img{
    height: 80%;
    width: 100%;
    border-radius: 20px;
    position: relative ;

}

.offer-container .offer-container-body button{
    position: relative;
    bottom: 42px;
    left: 50px;
    font-size: 1.5rem;
    background: var(--secondary-color);
    color: #fff;
    border-radius: 5px;
    text-transform: capitalize;
    padding: 5px;
    box-shadow: 1px 1px 12px -2px var(--secondary-color);

}

.offer-container .offer-container-body button:hover{
    background-color: #3f976d;
}